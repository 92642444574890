import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import Select from "react-select"

const useStyle = makeStyles(theme => ({
  root: {
    width: "95%",
  },
  disabled: {
    width: "95%",
    opacity: 0.5,
    "&:hover": {
      cursor: "not-allowed",
    },
  },
}))

function DrawerSelect({
  placeHolder,
  data,
  label,
  onChange,
  value,
  errors,
  isDisabled = false,
  customStyles = {},
  classesForSelect = {},
  customStylesForLabel = {},
  ...otherProps
}) {
  const classes = useStyle()

  const style = {
    control: base => ({
      ...base,
      border: 0,
      fontSize: "12px",
      fontFamily: "DM Sans",
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "5px",
      marginRight: "0px",
      borderRadius: 10,
      marginTop: "-3px",
      padding: "1.5px",
      backgroundColor: "#F5F6F8",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
    }),
  }

  const errorstyle = {
    control: (base, state) => ({
      ...base,
      border: 0,
      fontSize: "12px",
      fontFamily: "DM Sans",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      // This line disable the blue border
      boxShadow: "none",
      marginRight: "0px",
      borderRadius: 10,

      border: "1.5px solid #FD6E84 ",
    }),
  }

  const handleChange = e => {
    onChange(e)
  }

  return (
    <div
      style={{
        margin: "0 10px",
        marginLeft: "0 !important",
        width: "100%",
        ...customStyles,
      }}
    >
      <Typography
        style={{
          color: "#0A3133",
          marginBottom: "10px",
          fontSize: "12px",
          ...customStylesForLabel,
        }}
      >
        {label}
      </Typography>
      <Select
        placeholder={placeHolder}
        value={value}
        options={data}
        className={`${
          isDisabled ? classes.disabled : classes.root
        } ${classesForSelect}`}
        displayEmpty
        isDisabled={isDisabled}
        onChange={handleChange}
        isSearchable={false}
        getOptionLabel={e => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span style={{ marginLeft: 1, color: "#000000" }}>
              {e.name ? e.name : e.label}
            </span>
          </div>
        )}
        styles={
          errors
            ? {
                ...errorstyle,
              }
            : { ...style }
        }
        components={{
          IndicatorSeparator: () => null,
        }}
        {...otherProps}
      />
    </div>
  )
}
export default DrawerSelect
