import { IconButton, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import DrawerSelect from "./utils/DrawerSelect"
import DrawerTextField from "./utils/DrawerTextField"
import DrawerButton from "./utils/DrawerButton"
import addClubIcon from "../../../images/booking_plus.svg"
import minClubIcon from "../../../images/booking_min.svg"
import line from "../../../images/lineBook.svg"
import EditCourtItem from "./utils/EditCourtItems"
import delete_icon from "../../../images/delete_black.svg"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
  },
  rootForm: {
    width: "100%",
  },
  rootMainForm: {
    paddingLeft: "15px",
    paddingRight: "15px ",
  },
  dateTime: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginRight: "10px",
  },
  rootButton: {
    display: "flex",
    width: "97%",
    flexDirection: "row",
    justifyContent: "space-arround",
    alignItems: "center",
    bottom: "0",
    position: "absolute",
    marginBottom: "20px",
    overflowY: "hidden",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
}))

function EditCourt({ close }) {
  const classes = useStyles()

  const [formFields, setFormFields] = useState([])
  const [plus, setPlus] = useState(false)

  const addFields = () => {
    setFormFields([...formFields, "dummy"])
    setPlus(true)
  }

  const removeFields = index => {
    let data = [...formFields]
    data.splice(index, 1)
    setFormFields(data)
    setPlus(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
          Edit court
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon style={{ margin: "13px" }} />
        </IconButton>
      </div>
      <div className={classes.rootForm}>
        <div className={classes.rootMainForm}>
          <div style={{ marginRight: "15px" }}>
            <DrawerTextField placeHolder="Court name" label="Court name" />
          </div>
          <div className={classes.dateTime}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "0px",
              }}
            >
              <DrawerSelect
                align="center"
                placeHolder="Sports"
                label="Sports"
              />
            </div>
            <div
              style={{
                display: "flex",
                direction: "column",
                width: "100%",
                marginLeft: "10px",
                marginRight: "10px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DrawerSelect placeHolder="Outside" label="Outside/inside" />
            </div>
          </div>
          <div className={classes.rootButton}>
            <div style={{ width: "80%", padding: "20px" }}>
              <DrawerButton text="Save" />
            </div>
            <div style={{ width: "20%" }}>
              <img src={delete_icon} style={{ width: "60px" }} alt="" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              float: "left",
              marginLeft: "0px",
              marginTop: "5px",
            }}
          >
            {plus === false ? (
              <img
                src={addClubIcon}
                onClick={addFields}
                alt="add"
                style={{ marginLeft: "10px" }}
              />
            ) : (
              <img
                src={minClubIcon}
                onClick={removeFields}
                alt="add"
                style={{ marginLeft: "10px" }}
              />
            )}
            <Typography
              style={{
                fontFamily: "DM Sans",
                fontSize: "15px",
                marginLeft: "20px",
              }}
            >
              Advanced settings{" "}
            </Typography>

            <br />
          </div>
          <br />
          <img
            src={line}
            style={{
              width: 300,
              marginTop: "10px",
              marginBottom: "10px",
              paddingLeft: "30px",
            }}
            alt=""
          />
          <div>
            {formFields.map(index => {
              return (
                <div key={index}>
                  <EditCourtItem
                    handleDeleteClick={() => removeFields(index)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCourt
