import { makeStyles } from "@material-ui/core"
import React from "react"
import DrawerSelect from "./DrawerSelect"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "../../../../redux/slices/seetingsCourtSlice"
import { DATA_BOOKING, DATA_STATUS } from "../../../../utils/constants"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "0px",
    marginTop: "20px",
  },
  rootForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "10px",
    marginTop: "-20px",
  },
}))

function EditCourtItem() {
  const classes = useStyles()

  const dispatch = useDispatch()

  const courtFormState = useSelector(
    state => state.courtSettingsReducer.courtInfo
  )

  const handleChange = (key, value) => {
    dispatch(actions.courtFieldsInfo({ key1: "courtInfo", key2: key, value }))
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootForm}>
        <div style={{ width: "48%", marginLeft: "0px" }}>
          <DrawerSelect
            onChange={value => handleChange("status", value)}
            value={courtFormState?.status}
            data={DATA_STATUS}
            placeHolder="Status"
            label="Status"
          />
        </div>
        <div style={{ width: "48%", marginLeft: "10px", marginRight: "10px" }}>
          <DrawerSelect
            onChange={value => handleChange("bookingLength", value)}
            value={courtFormState?.bookingLength}
            data={DATA_BOOKING}
            placeHolder="Booking length"
            label="Booking length"
          />
        </div>
      </div>
    </div>
  )
}

export default EditCourtItem
